/*
 *  Created by Rouven Haake 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

const Header = () => {
  const navigate = useNavigate();

  return (
    <Navbar variant="primary" className="bg-white">
      <Container fluid>
        <Navbar.Brand
          onClick={() => navigate("")}
          className="d-flex align-items-center px-2 gap-3"
        >
          <img src="iav_logo_base.png" alt="IAV logo" height={23} />
          <span>Trace Information</span>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
