/*
 *  Created by Rouven Haake 2022, Christopher Lang 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "../login/Login";
import UseToken from "../login/UseToken";

import Header from "./Header";
import Page from "./Page";

const Routing = () => {
  const { token, setToken } = UseToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <>
      <Router>
        <Header />
        <div className="main-body">
          <Routes>
            <Route exact path="overview" element={<Page />} />
            <Route path="*" element={<Navigate to="/overview" />} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default Routing;
