/*
 *  Created by Rouven Haake 2022, Christopher Lang 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

/* eslint-disable no-unused-vars */
import React from "react";
import { useSearchParams } from "react-router-dom";
import Split from "react-split";

import TraceDetails from "../trace-information/TraceDetails";
import TopicList from "../trace-information/TopicList";
import TopicDetails from "../trace-information/TopicDetails";
import { MdOutlineManageSearch } from "react-icons/md";

const Overview = ({ meta, replays }) => {
  const [searchParams] = useSearchParams();
  const topic = searchParams.get("topic") || "";
  const trace = searchParams.get("trace") || "";
  var currentMdFile = null;
  var currentTraceFile = null;

  if (trace) {
    let index = replays.map((item) => item.trace[0].traceName).indexOf(trace);

    if (index > -1) {
      currentTraceFile = replays[index].trace[0];
      currentMdFile = meta[replays[index].identifier + ".md"];
    }
  }

  if (!trace) {
    return (
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <MdOutlineManageSearch size={56} className="opacity-25" />
      </div>
    );
  }

  return (
    <Split className="split" sizes={[20, 30, 50]} minSize={400}>
      {currentMdFile && (
        <div className="trace-markdown-container flex-fill dashboard-panel shadow-iav">
          <TraceDetails
            currentMdFile={currentMdFile}
            traceFile={currentTraceFile}
          />
        </div>
      )}
      {currentTraceFile && (
        <div className="trace-information-container flex-fill dashboard-panel shadow-iav px-2">
          <TopicList traceFile={currentTraceFile} />
        </div>
      )}

      <div className="topic-detail-container flex-fill dashboard-panel shadow-iav">
        <TopicDetails topic={topic} trace={trace} replays={replays} />
      </div>
    </Split>
  );
};

export default Overview;
