/*
 *  Created by Christopher Lang 2022, Rouven Haake 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IconContext } from "react-icons";
import { FaSearch } from "react-icons/fa";

import Overview from "../trace-information/Overview";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

const Filter = ({ details, replays, meta }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTopic = searchParams.get("topic") || "";
  const selectedTrace = searchParams.get("trace") || "";

  const [searchField, setSearchField] = useState("");

  const filteredTopics = details.filter((topicObject) => {
    return topicObject.name.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleChange = (e) => setSearchField(e.target.value);

  const topicPerTrace = (traceReplays) => {
    let topicCounts = [];
    for (var trace in traceReplays) {
      for (var topicId in traceReplays[trace].trace[0].topics) {
        if (
          traceReplays[trace].trace[0].topics[topicId].topic === selectedTopic
        ) {
          topicCounts.push({
            trace_id: trace,
            count: traceReplays[trace].trace[0].topics[topicId].count,
          });
        }
      }
    }
    return topicCounts;
  };

  const Dropdown = ({ replay }) => {
    const handleTraceChange = (e) => {
      const value = e.target.value;
      setSearchParams(
        { topic: selectedTopic, trace: value },
        { replace: false }
      );
    };
    return (
      <Form.Select value={selectedTrace} onChange={handleTraceChange}>
        <option value=""> -- Select a trace -- </option>
        {replay.map((traceReplay) => (
          <option
            key={traceReplay.trace[0].traceName}
            value={traceReplay.trace[0].traceName}
          >
            {traceReplay.trace[0].traceName}
          </option>
        ))}
      </Form.Select>
    );
  };

  const SearchList = ({ filteredTopics }) => {
    if (!filteredTopics?.length) {
      return <div>loading</div>;
    }

    const onTopicClick = (topic) => {
      const value = topic.topic_url;
      setSearchParams(
        { topic: value, trace: selectedTrace },
        { replace: false }
      );
    };

    return (
      <ul>
        {filteredTopics.map((topic, index) => (
          <li key={index} className="topic-line">
            <button
              className="topic-btn text-start"
              onClick={() => onTopicClick(topic)}
            >
              {topic.name}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const TopicCount = ({ replay }) => {
    const topicCounts = topicPerTrace(replay);

    const onClick = (currentTrace) => {
      const trace = replay[currentTrace.trace_id].trace[0].traceName;
      setSearchParams({ topic: selectedTopic, trace }, { replace: false });
    };

    return (
      <div>
        <table className="table table-hover topic-count-table">
          <thead style={{ position: "sticky", top: 0 }}>
            <tr>
              <th className="topic-count-header" scope="col">
                Trace name
              </th>
              <th className="topic-count-header" scope="col">
                # Occurrences
              </th>
            </tr>
          </thead>
          <tbody>
            {topicCounts.map((currentSelection) => (
              <tr
                key={currentSelection.trace_id}
                onClick={() => onClick(currentSelection)}
              >
                <td>{replay[currentSelection.trace_id].trace[0].traceName}</td>
                <td>{currentSelection.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column main-body p-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {searchParams.get("topic") && (
            <>
              <span className="selection-span">Selected Topic:</span>
              <div className="selection-topic-item">
                {
                  details.find(
                    (el) => el.topic_url === searchParams.get("topic")
                  ).name
                }
              </div>
            </>
          )}
        </div>

        <div className="d-flex gap-2">
          <Dropdown replay={replays} />

          <IconContext.Provider value={{ className: "fa-search" }}>
            <InputGroup className="search-input">
              <InputGroup.Text className="bg-white">
                <FaSearch fill="#001a54" />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search"
                onChange={handleChange}
              />
            </InputGroup>
          </IconContext.Provider>
        </div>
      </div>

      <Accordion className="shadow-iav mt-2">
        <Accordion.Item>
          <Accordion.Header>Show/Hide Filter</Accordion.Header>
          <Accordion.Body className="d-flex filter-list">
            <div className="topic-list flex-grow-1 me-2">
              <SearchList filteredTopics={filteredTopics} />
            </div>
            {searchParams.get("topic") && (
              <div className="topic-count">
                <TopicCount replay={replays} />
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <section className="mt-2 tile-container">
        <Overview meta={meta} replays={replays} />
      </section>
    </div>
  );
};

export default Filter;
