/*
 *  Created by Rouven Haake 2022, Christopher Lang 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

import React from "react";

const stringify = require("json-stringify-pretty-compact");

const TopicDetails = ({ topic, trace, replays }) => {
  const topicPerTrace = (traceReplays, topic) => {
    let topicCounts = [];

    for (var trace in traceReplays) {
      for (var id in traceReplays[trace].trace[0].topics) {
        if (traceReplays[trace].trace[0].topics[id].topic === topic) {
          let jsonTopic = topic.split("/");

          switch (jsonTopic[0]) {
            case "someip":
              if ("key" in traceReplays[trace].trace[0].topics[id]) {
                jsonTopic.splice(0, 0, undefined);
                break;
              }
              jsonTopic.splice(4, 0, "json-dev");
              break;
            case "can":
              if ("key" in traceReplays[trace].trace[0].topics[id]) {
                jsonTopic.splice(0, 0, undefined);
                break;
              }
              jsonTopic.splice(2, 0, "json-dev");
              break;
            case "pdu":
              if ("key" in traceReplays[trace].trace[0].topics[id]) {
                jsonTopic.splice(0, 0, undefined);
                break;
              }
              jsonTopic.splice(2, 0, "json-dev");
              break;
            default:
              jsonTopic.splice(0, 0, undefined);
              break;
          }

          var jsonDevEntry;
          if (jsonTopic[0]) {
            jsonDevEntry = traceReplays[trace].trace[0].topics.filter((obj) => {
              return obj.topic === jsonTopic.join("/");
            });
          } else {
            jsonDevEntry = [{ topic: undefined, result: undefined }];
          }

          topicCounts.push({
            trace_id: trace,
            trace_name: traceReplays[trace].trace[0].traceName,
            count: traceReplays[trace].trace[0].topics[id].count,
            topic_id: id,
            topic: topic,
            dev_topic: jsonDevEntry[0].topic,
            dev_schema: jsonDevEntry[0].result,
          });
        }
      }
    }
    return topicCounts;
  };

  var topicCounts = topicPerTrace(replays, topic);

  let results = {
    trace_id: 0,
    count: undefined,
    topic_id: undefined,
    dev_topic: undefined,
    dev_schema: undefined,
  };

  for (var element of topicCounts) {
    // Replace values with placeholder in json schema
    // element.dev_schema = changeValue(element.dev_schema);
    if (element.topic === topic && element.trace_name === trace) {
      results = element;
    }
  }

  return (
    <>
      <div id="results" className="p-2">
        {results.trace_name ? (
          <ul>
            <li>
              <div className="topic-detail-header">Topic Info: </div>
              <pre className="topic-detail-data">
                <table className="topic-detail-table">
                  <tbody>
                    <tr>
                      <td>Topic name:</td>
                      <td>
                        &quot;
                        {
                          replays[results.trace_id].trace[0].topics[
                            results.topic_id
                          ].topic
                        }
                        &quot;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Occurrence sum: <br></br>
                        <div className="topic-detail-info">
                          How often this topic is triggered <br></br>
                          in the related trace.
                        </div>
                      </td>
                      <td>
                        {
                          replays[results.trace_id].trace[0].topics[
                            results.topic_id
                          ].count
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Payloads / Schemas: <br></br>
                        <div className="topic-detail-info">
                          Some topics may contain different <br></br>
                          payloads which are all listed here.
                        </div>
                      </td>
                      <td>
                        <table className="topic-detail-schemas">
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Occurrence</th>
                            </tr>
                          </thead>
                          <tbody>
                            {React.Children.toArray(
                              replays[results.trace_id].trace[0].topics[
                                results.topic_id
                              ].result.map((payload, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{stringify(payload.schema)}</td>
                                      <td>{payload.count}</td>
                                    </tr>
                                  </>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </pre>
            </li>
            <li>
              <div className="topic-detail-header">Development Topic: </div>
              <div className="topic-detail-info">
                Be aware: This topic is not available in the production ready
                vehicle and is intended for rapid prototyping and debugging
                only.
              </div>{" "}
              <br></br>
              <pre className="topic-detail-data">
                {results.dev_topic ? (
                  '"' + results.dev_topic + '"'
                ) : (
                  <div>This topic does not have a development alternative.</div>
                )}
              </pre>
            </li>
            {results.dev_topic ? (
              <li>
                <div className="topic-detail-header">
                  Development Topic example JSON Payload:{" "}
                </div>
                <div className="topic-detail-info">
                  &quot;json-dev&quot; topics payload will always be an
                  json-formatted UTF8 string. The received payload will contain
                  the values in the following format:
                </div>{" "}
                <br></br>
                <pre className="topic-detail-data">
                  <table className="topic-detail-schemas">
                    <tbody>
                      {React.Children.toArray(
                        results.dev_schema.map((payload) => {
                          return (
                            <>
                              <tr>
                                <td>Schema:</td>
                                <td>{stringify(payload.schema)}</td>
                              </tr>
                              <tr>
                                <td>Occurrence:</td>
                                <td>{payload.count}</td>
                              </tr>
                            </>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </pre>
              </li>
            ) : (
              <div></div>
            )}
          </ul>
        ) : (
          <div className="alert alert-warning" role="alert">
            This topic doesn&apos;t exist in &quot;{trace}&quot;.
          </div>
        )}
      </div>
    </>
  );
};

export default TopicDetails;
