/*
 *  Created by Rouven Haake 2022, Christopher Lang 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

/* eslint-disable react/no-children-prop */
import React from "react";
import parse, { domToReact } from "html-react-parser";

const parserOptions = {
  replace: ({ type, name, children }) => {
    if (type === "tag" && name === "h3") {
      return (
        <div className="trace-markdown-header">
          {domToReact(children, parserOptions)}
        </div>
      );
    }

    if (type === "tag" && name === "table") {
      return (
        <table className="table table-bordered table-sm">
          {domToReact(children, parserOptions)}
        </table>
      );
    }

    return;
  },
};

const TraceDetails = ({ currentMdFile, traceFile }) => {
  // Recording date
  var recordingDate = new Date(traceFile.firstTimeEpoch * 1000);
  let formatted_date =
    recordingDate.getDate() +
    "." +
    (recordingDate.getMonth() + 1) +
    "." +
    recordingDate.getFullYear() +
    " " +
    recordingDate.getHours() +
    ":" +
    recordingDate.getMinutes() +
    ":" +
    recordingDate.getSeconds();

  // Duration
  var duration = 0;
  const seconds = traceFile.lastTimeEpoch - traceFile.firstTimeEpoch;

  duration = new Date(seconds * 1000).toISOString().slice(11, 19);

  // File size
  const fileSize = Math.round(traceFile.traceFileSize / 1000);

  return (
    <>
      <div className="p-2">
        <div className="trace-markdown-header">Trace Information</div>
        <table className="table table-borderless table-sm">
          <tbody>
            <tr>
              <td className="fw-bold">File size:</td>
              <td>{fileSize + " kB"}</td>
            </tr>
            <tr>
              <td className="fw-bold">Recording date:</td>
              <td>{formatted_date}</td>
            </tr>
            <tr>
              <td className="fw-bold">Duration:</td>
              <td> {duration} </td>
            </tr>
          </tbody>
        </table>
        {parse(currentMdFile.content, parserOptions)}
      </div>
    </>
  );
};

export default TraceDetails;
