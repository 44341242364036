/*
 *  Created by Christopher Lang 2022, Rouven Haake 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */

import React from "react";
import axios from "axios";

const Login = ({ setToken }) => {
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [err, setError] = React.useState("");
  const [failedLogin, setFailedLogin] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        "/api/login",
        {
          username,
          password,
        },
        config
      );
      setToken(data);
    } catch (error) {
      // Set div shake for 100ms to indicate failed login attempt
      setError(error.response.data);
      setFailedLogin(true);
      console.log(err);
      setTimeout(function () {
        setFailedLogin(false);
      }, 200);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className={failedLogin ? "shake" : null}>
        <div className="auth-inner">
          <form onSubmit={handleSubmit} className="login-form">
            <h3>Login</h3>
            <div className="mb-3">
              <label className="form-label">Username</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-error">{err}</div>
            <button type="submit" className="btn btn-primary btn-block">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
