/*
*  Created by Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

import { useState } from "react";

const UseToken = () => {
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
};
export default UseToken;
