/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

/* eslint-disable no-unused-vars */
import React from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname, params) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` });
};

const TopicList = ({ traceFile }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTopic = searchParams.get("topic") || "";
  const initialTrace = searchParams.get("trace") || "";

  const navigateSearch = useNavigateSearch();


  const createTopicArray = () => {
    var topicArray = [];
    if (traceFile) {
      for (var topicIndex in traceFile.topics) {
        if ("key" in traceFile.topics[topicIndex]) {
          topicArray.push(traceFile.topics[topicIndex]);
        }
      }
    }
    // sort array
    topicArray = topicArray.sort((a, b) => (a.topic > b.topic ? 1 : -1));
    return topicArray;
  };

  const goToPosts = (selection, topic) =>
    navigateSearch("/overview", { topic: topic, trace: selection });

  const onClick = (topic) => {
    if (topic !== initialTopic) {
      goToPosts(initialTrace, topic.topic_url);
    }
  };

  return (
    <>
      <table className="table table-hover topic-trace-table">
        <thead style={{ position: "sticky", top: 0 }}>
          <tr>
            <th>Topic in Trace</th>
            <th># Occurrences</th>
          </tr>
        </thead>
        <tbody>
          {createTopicArray().map((el, index) => (
            <tr onClick={() => onClick(el)} key={traceFile.traceName + index}>
              <td className="column-max-width text-truncate">{el.name}</td>
              <td>{el.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TopicList;
