/*
 *  Created by Rouven Haake 2022, Christopher Lang 2022
 *  Copyright © 2022 IAV GmbH. All rights reserved.
 */
import React, { useEffect, useState } from "react";

import axios from "axios";

import Filter from "../search/Filter";

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [topicList, setTopicList] = useState([]);
  const [traceReplays, setTraceReplays] = useState([]);
  const [traceMetaData, setTraceMetaData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var sortedTopicList = [];

    const requestRawTraceFiles = axios.get("/api/trace-raw-files");
    const requestMetaData = axios.get("/api/trace-metadata");
    const requestTopicList = axios.get("/api/topic-list");

    axios.all([requestRawTraceFiles, requestMetaData, requestTopicList]).then(
      axios.spread((...responses) => {
        const responseTraceFiles = responses[0];

        const responseMetaData = responses[1];
        const responseTopicList = responses[2];
        sortedTopicList = responseTopicList.data.sort((a, b) =>
          a.topic > b.topic ? 1 : -1
        );

        setTraceMetaData(responseMetaData.data);
        setTopicList(sortedTopicList);
        setTraceReplays(responseTraceFiles.data);
        setLoading(false);
      })
    );
  };

  return (
    <>
      {loading ? (
        <div className="main-body d-flex justify-content-center align-items-center">
          Loading...
        </div>
      ) : (
        <Filter
          details={topicList}
          replays={traceReplays}
          meta={traceMetaData}
        />
      )}
    </>
  );
};
export default Page;
